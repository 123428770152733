<template>
  <div class="mainBox page-container">
    <!--    面包屑导航区-->
<!--    <el-breadcrumb separator-class="el-icon-arrow-right">-->
<!--      <el-breadcrumb-item :to="{ path: '/welcome' }">主页</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>患者管理</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>患者处理</el-breadcrumb-item>-->
<!--    </el-breadcrumb>-->
    <Breadcrumb></Breadcrumb>

    <!--  操作区-->
    <el-card>
      <el-form
        :inline="true"
        :model="selectInfo"
        class="demo-form-inline"
        size="small"
        ref="selectFormRef"
      >
        <el-form-item label="患者信息" style="margin-right: 30px" prop="userInfo">
          <el-input v-model="selectInfo.userInfo" placeholder="请输入患者姓名/手机号码"></el-input>
        </el-form-item>
        <el-form-item label="注册时间" style="margin-right: 30px" prop="registime">
          <el-date-picker
            v-model="selectInfo.registime"
            type="daterange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00','23:59:59']"
            :clearable="false"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="注册地址" style="margin-right: 30px" prop="regadress">
          <el-cascader
            v-model="selectInfo.regadress"
            :options="options"
            :props="{ expandTrigger: 'hover', }"
            @change="handleChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item
          label="分配地址"
          style="margin-right: 30px"
          prop="dispatchAdress"
          v-if="activeName == 'second'"
        >
          <el-cascader
            v-model="selectInfo.dispatchAdress"
            :options="options"
            :props="{ expandTrigger: 'hover', }"
            @change="handleChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="resetSelectOptions">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <div class="formregion">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="未分配" name="first">
          <el-table :data="toAssigneList" border style="width: 100%" size="small">
            <el-table-column type="index" label="序号" align="center"></el-table-column>
            <el-table-column prop="name" label="患者姓名"  align="center"></el-table-column>
            <el-table-column prop="phone" label="手机号码"  align="center"></el-table-column>
            <el-table-column prop="createTime" label="注册时间"  align="center">
              <template
                slot-scope="socpe"
              >{{ socpe.row.createTime | dateFormat('YYYY-MM-DD HH:mm') }}</template>
            </el-table-column>
            <el-table-column prop="province" label="注册地址"  align="center">
              <template slot-scope="scope">
                <span
                  v-if="scope.row.province || scope.row.city"
                >{{ scope.row.province + scope.row.city }}</span>
                <span v-else-if="scope.row.province == '' || scope.row.city == ''">——</span>
              </template>
            </el-table-column>
            <el-table-column prop="adress" label="操作列" width="300" align="center">
              <template slot-scope="scope">
                <el-button @click="dispatchPatient(scope.row)" type="text" size="small">分配患者</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="已分配" name="second">
          <el-table :data="assignedList" border style="width: 100%" size="small">
            <el-table-column type="index" label="序号" align="center"></el-table-column>
            <el-table-column prop="name" label="患者姓名"  align="center"></el-table-column>
            <el-table-column prop="phone" label="手机号码"  align="center"></el-table-column>
            <el-table-column prop="createTime" label="注册时间"  align="center">
              <template
                slot-scope="socpe"
              >{{ socpe.row.createTime | dateFormat('YYYY-MM-DD HH:mm') }}</template>
            </el-table-column>
            <el-table-column prop="sellerCity" label="注册地址"  align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.userCity || scope.row.userProvince">{{ scope.row.userProvince }}{{scope.row.userCity}}</span>
                <span v-else>——</span>
              </template>
            </el-table-column>
            <el-table-column prop="disName" label="分配地址"  align="center"></el-table-column>
            <el-table-column prop="sellerName" label="分配销售"  align="center"></el-table-column>
            <el-table-column prop="sellerPhone" label="销售电话" width="130" align="center"></el-table-column>
            <el-table-column prop="groupName" label="医生工作室"  align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.groupName">{{ scope.row.groupName }}</span>
                <span v-if="scope.row.groupName == null">——</span>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div class="page_warpper">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="selectInfo.pagenum"
            :page-sizes="[10, 20, 50]"
            :page-size="selectInfo.pagesize"
            layout="slot, sizes, prev, pager, next, jumper"
            :total="total"
            style="margin-left: 30px">
          <span class="total">共 {{ total }} 条数据，共 {{ Math.ceil(total / selectInfo.pagesize) }} 页</span>
        </el-pagination>
      </div>
    </div>

    <!--    添加工单对话框-->
    <el-dialog
      title="分配患者"
      :visible.sync="dispatchDialogVisiable"
      width="30%"
      @close="dispatchDialogClose"
      :modal="false"
    >
      <!--      内容主体区-->
      <el-form :model="dispatchForm" ref="dispatchFormRef" label-width="80px">
        <el-form-item label="注册地址" prop="adress" label-width="100px">
          <el-input v-model="dispatchForm.adress" disabled></el-input>
        </el-form-item>
        <el-form-item label="选择地址" prop="dispatchAdress" label-width="100px">
          <el-cascader
            v-model="dispatchForm.dispatchAdress"
            :options="options"
            :props="{ expandTrigger: 'hover', }"
            @change="handleChangeDispatch"
            filterable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="选择工作室" prop="groupId" label-width="100px">
          <el-cascader
              v-model="dispatchForm.groupId"
              :options="officeSelectList"
              :props="{ expandTrigger: 'hover'}"
              @change="handleChangeOffice"
              filterable
          ></el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDispatch">取 消</el-button>
        <el-button type="primary" @click="dispatchPatientConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { provinceAndCityData, CodeToText } from 'element-china-area-data'
export default {
  name: "taskcenter",
  data() {
    return {
      options: provinceAndCityData,
      //添加工单管理对话框
      dispatchDialogVisiable: false,
      //添加工单对话框信息内容
      dispatchForm: {
        adress: '',
        dispatchAdress: [],
        groupId:[]
      },
      //总条数
      total: 0,
      cityArr: {},
      selectInfo: {
        userInfo: '',
        region: '',
        registime: '',
        regadress: '',
        dispatchAdress: '',
        pagenum: 1,
        //当前每页展示的数据条数
        pagesize: 10
      },
      activeName: 'first',
      //待分配患者列表
      toAssigneList: [],
      //已分配患者列表
      assignedList: [],
      //分配患者参数
      dispatchParams: {
        distributionProvince: "",
        distributionCity: "",
        userId: "",
        customerServerId:"",
        groupId:''
      },
      //工作室列表
      officeSelectList:[]

    }
  },
  created() {
    this.getDefaultPatientList()
    this.dispatchParams.customerServerId = parseInt(JSON.parse(sessionStorage.getItem('userInfo')).customerServerId)
    console.log( this.dispatchParams.customerServerId)
  },
  methods: {
    onSubmit() {
      //待分配参数
      if (CodeToText[this.selectInfo.regadress[1]] == '市辖区') {
        this.selectInfo.regadress[1] = this.selectInfo.regadress[0]
      }
      if (CodeToText[this.selectInfo.dispatchAdress[1]] == '市辖区') {
        this.selectInfo.dispatchAdress[1] = this.selectInfo.dispatchAdress[0]
      }
      let _paramsY = {
          userInfo: this.selectInfo.userInfo,
          startRegister: this.selectInfo.registime[0] ? this.selectInfo.registime[0] : '',
          endRegister: this.selectInfo.registime[1] ? this.selectInfo.registime[1] : '',
          province: this.selectInfo.regadress[0] ? CodeToText[this.selectInfo.regadress[0]] : '',
          city: this.selectInfo.regadress[1] ? CodeToText[this.selectInfo.regadress[1]] : '',
          page: '',
          pageSize: ''
      }
      let _paramsN = {
          userInfo: this.selectInfo.userInfo,
          startRegister: this.selectInfo.registime[0] ? this.selectInfo.registime[0] : '',
          endRegister: this.selectInfo.registime[1] ? this.selectInfo.registime[1] : '',
          province: this.selectInfo.regadress[0] ? CodeToText[this.selectInfo.regadress[0]] : '',
          city: this.selectInfo.regadress[1] ? CodeToText[this.selectInfo.regadress[1]] : '',
          // distributionProvince: this.selectInfo.dispatchAdress[0] ? CodeToText[this.selectInfo.dispatchAdress[0]] : '',
          distributionCity: this.selectInfo.dispatchAdress[1] ? CodeToText[this.selectInfo.dispatchAdress[1]] : '',
          page: '',
          pageSize: '',
          customerServerId:this.dispatchParams.customerServerId
      }
      if (this.activeName == 'first') {
        console.log(_paramsY,'================')
        this.getToAsignePatiensList(_paramsY)
      } else if (this.activeName == 'second') {
        console.log(_paramsN,'================')
        this.getAsignedPatiensList(_paramsN)
      }
    },
    cancelDispatch(){
      this.dispatchDialogVisiable = false
      this.officeSelectList=[]
    },
    //tab切换按钮
    handleClick(tab, event) {
      console.log(tab, event);
      this.getDefaultPatientList()
    },
    handleSizeChange(val) {
      this.selectInfo.pagesize = val
      console.log(this.selectInfo.pagesize)
      this.toPages()
    },
    handleCurrentChange(val) {
      this.selectInfo.pagenum = val
      console.log(this.selectInfo.pagenum)
      this.toPages()
    },
    //重置按钮事件
    resetSelectOptions() {
      this.$refs.selectFormRef.resetFields()
      this.getDefaultPatientList()
      console.log('重置成功')
    },
    //分配患者对话框关闭事件
    dispatchDialogClose() {
      console.log('分配患者对话框关闭')
      this.$refs.dispatchFormRef.resetFields()
    },
    //
    handleChange(adressArr) {
      console.log('级联选择器改变事件')
      console.log(adressArr)
    },
    //分配患者级联选择器
    async handleChangeDispatch(adressArr) {
      console.log(adressArr)
      if (CodeToText[adressArr[1]] == '市辖区') {
        this.dispatchParams.distributionCity = CodeToText[adressArr[0]]
        this.dispatchParams.distributionProvince = CodeToText[adressArr[0]]
      } else {
        this.dispatchParams.distributionProvince = CodeToText[adressArr[0]]
        this.dispatchParams.distributionCity = CodeToText[adressArr[1]]
      }
      if (adressArr.length==2){
        let params = {
          regionName: CodeToText[adressArr[1]]=='市辖区'?CodeToText[adressArr[0]] : CodeToText[adressArr[1]]
        }
        console.log(params)
        const res = await this.$http.getDoctorGroupNames(params)
        console.log(res)
        if (res.result.data.length==0) {
          this.$message.error('该地区暂无医院和工作室,无法分配!')
          this.officeSelectList=[]
        }else {
          res.result.data.forEach((item,index)=>{
            item.doctorGroupNames=JSON.parse(item.doctorGroupNames)
          })
          console.log(res)
          const newArr = JSON.parse(JSON.stringify(res.result.data).replace(/hospitalId/g, 'value').replace(/name/g, 'label').replace(/doctorGroupNames/g, 'children').replace(/group_/g, '').replace(/id/g, 'value'))
          this.officeSelectList = newArr
          console.log(newArr,'=====================')
        }
      }else return

    },
    //选择工作室级联选择器
    handleChangeOffice(adressArr){
      console.log(adressArr)
      console.log(this.dispatchForm.groupId)
    },
    //确认分配患者
    dispatchPatientConfirm() {
      console.log('确认分配患者')
      console.log(this.dispatchParams,'打印参数==============================')
      if (this.dispatchParams.distributionCity == '' || this.dispatchParams.distributionProvince == '') return this.$message.error('请先选择省市!')
      if (this.dispatchForm.groupId.length<=1)return this.$message.error('请先选择医院和工作室!')
      let _params = {
        customerServerId:JSON.parse(sessionStorage.getItem('userInfo')).customerServerId,
        userId:this.dispatchParams.userId,
        doctorGroupId:this.dispatchForm.groupId[1]
      }
      console.log(_params,'分配参数================================')
      this.$http.dispatchPatiens(_params).then((result) => {
        console.log(result,'=======================分配结果')
        if (result.result.code !== 1) {
          this.$message.error('分配失败!')
          this.dispatchDialogVisiable = false
        } else {
          this.$message.success('分配成功!')
          this.dispatchDialogVisiable = false
          this.getDefaultPatientList()
          console.log('关闭对话框')
        }
      }).catch(error => {
        console.log(error.response,'打印错误=============')
        if (error.response.data.status == 500 && error.response.data.message.indexOf('工作室未绑定销售,绑定销售失败!')!=-1) {
          this.$message.error('该工作室暂无销售,无法分配!')
          this.dispatchDialogVisiable = false
        }
      })
      // console.log(res)

    },
    dispatchPatient(row) {
      this.dispatchDialogVisiable = true
      console.log(row.province==undefined)
      this.dispatchParams.userId = row.userId + ''
      if (row.province==undefined || row.city== undefined){
        this.dispatchForm.adress='暂无注册地址'
      }else {
        this.dispatchForm.adress = row.province + row.city
      }
    },
    //获取默认待分配患者列表
    async getDefaultPatientList() {
      let _paramsY = {
          userInfo: '',
          startRegister: '',
          endRegisterd: '',
          province: '',
          city: '',
          page: '',
          pageSize: ''
      }
      let _paramsN = {
          userInfo: '',
          startRegister: '',
          endRegisterd: '',
          province: '',
          city: '',
          page: '',
          pageSize: '',
          distributionProvince:'',
          distributionCity:'',
          customerServerId:this.dispatchParams.customerServerId
      }
      if (this.activeName == 'first') {
        this.getToAsignePatiensList(_paramsY)
      } else if (this.activeName == 'second') {
        this.getAsignedPatiensList(_paramsN)
      }
    },
    //获取待分配列表数据
    async getToAsignePatiensList(params) {
      console.log('即将获取待分配')
      const res = await this.$http.getPatientsToAsigne(params)
      console.log(res,'获取待分配列表数据======================')
      this.selectInfo.pagesize = res.result.data.rows
      this.total = res.result.data.records
      this.selectInfo.pagenum = res.result.data.page
      console.log(res)
      if (res.code !== 200) {
        return this.$message.error('数据获取失败!请稍后再试')
      } else {
        this.toAssigneList = res.result.data.data
        console.log(this.toAssigneList)
      }
    },
    //获取已分配列表数据
    async getAsignedPatiensList(params) {
      console.log('即将获取已分配')
      console.log('已分配参数',params)
      const res = await this.$http.getPatientsAsigned(params)
      console.log(res,'获取已分配列表数据========================')
      this.selectInfo.pagesize = res.result.data.rows
      this.total = res.result.data.records
      this.selectInfo.pagenum = res.result.data.page
      console.log(res)
      if (res.code !== 200) {
        return this.$message.error('数据获取失败!请稍后再试')
      } else {
        this.assignedList = res.result.data.data
        console.log(this.assignedList)
      }
    },
    //分页请求
    toPages() {
      let _paramsY = {
          userInfo: this.selectInfo.userInfo,
          startRegister: this.selectInfo.registime[0] ? this.selectInfo.registime[0] : '',
          endRegister: this.selectInfo.registime[1] ? this.selectInfo.registime[1] : '',
          province: this.selectInfo.regadress[0] ? CodeToText[this.selectInfo.regadress[0]] : '',
          city: this.selectInfo.regadress[1] ? CodeToText[this.selectInfo.regadress[1]] : '',
          page: this.selectInfo.pagenum,
          pageSize: this.selectInfo.pagesize
      }
      let _paramsN = {
          userInfo: this.selectInfo.userInfo,
          startRegister: this.selectInfo.registime[0] ? this.selectInfo.registime[0] : '',
          endRegister: this.selectInfo.registime[1] ? this.selectInfo.registime[1] : '',
          province: this.selectInfo.regadress[0] ? CodeToText[this.selectInfo.regadress[0]] : '',
          city: this.selectInfo.regadress[1] ? CodeToText[this.selectInfo.regadress[1]] : '',
          distributionProvince: this.selectInfo.dispatchAdress[0] ? CodeToText[this.selectInfo.dispatchAdress[0]] : '',
          distributionCity: this.selectInfo.dispatchAdress[1] ? CodeToText[this.selectInfo.dispatchAdress[1]] : '',
          page: this.selectInfo.pagenum,
          pageSize: this.selectInfo.pagesize,
          customerServerId:this.dispatchParams.customerServerId
      }
      if (this.activeName == 'first') {
        this.getToAsignePatiensList(_paramsY)
      } else if (this.activeName == 'second') {
        this.getAsignedPatiensList(_paramsN)
      }
    }
  }
}
</script>

<style scoped>
.mainBox {
  display: flex;
  flex-direction: column;
}
.el-card {
  flex-shrink: 0;
}
.el-form {
  margin-left: 20px;
}
.el-form-item {
  margin-right: 50px;
  margin-top: 10px;
}
/*.el-tabs {*/
/*  margin-left: 30px;*/
/*}*/
.el-tab-pane {
  max-height: 560px;
  overflow-y: auto;
}
.formregion {
  background-color: #ffffff;
  margin-top: 10px;
  border-radius: 10px 10px 10px 0;
  padding: 0 20px;
  flex: 1;
}
.page_warpper {
  background-color: #ffffff;
  height: 40px;
  padding: 20px;
  border-radius: 0 0 0 10px;
}
.total {
  margin-right: 24px;
  font-weight: 400 !important;
  color: #606266;
}
</style>